enum ModuleConfig {
  HASH_PASSWORD = 'ModuleHashPassword',
  WALKIN_ORDER = 'ModuleKioskWalkInOrderIsEnabled',
  PHONE_ASK_ENALBED = 'ModuleKioskPhoneAskEnabled',
  OFFLINE_PAYMENT_ENABLED = 'ModuleKioskOfflinePaymentEnabled',
  PRODUCT_COMMENTS_ENABLED = 'ModuleProductCommentsEnabled',
  CREDITCARD_TIP_ENABLED = 'ModuleCreditCardTipEnabled',
}

export default ModuleConfig
